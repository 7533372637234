<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="VehicleRequest">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <vehicle-request-new @updated="$refs.smartTable.getTableRows()" :canReadOnly="canReadOnly"/>
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- vehicle -->
        <td>
          <template v-if="item.vehicle">{{ item.vehicle.code }} - {{ item.vehicle.label }}</template>
        </td>

        <!-- equipment / note -->
        <td>
          {{ item.note }}
        </td>

        <!-- type -->
        <td>
          {{ translationsUtil('vehicleOperation_' + item.type) }}
        </td>

        <!-- priority-->
        <td>
          <v-chip small label class="status-chip mr-2" :color="$store.getters.priorities[item.priority].background">
            <div :style="[{ color: $store.getters.priorities[item.priority].color }]">
              {{ $store.getters.priorities[item.priority].text }}
            </div>
          </v-chip>
        </td>

        <!-- date planned -->
        <td>
          {{ item.datePlannedStr ? momentDate(item.datePlannedStr) : '' }}
        </td>

        <!-- status -->
        <td>
          <v-chip small label class="status-chip mr-2" :color="$store.getters.vehicleRequestStatuses[item.status].background">
            <div :style="[{ color: $store.getters.vehicleRequestStatuses[item.status].color }]">
              {{ $store.getters.vehicleRequestStatuses[item.status].text }}
            </div>
          </v-chip>
        </td>

        <!-- date from -->
        <td>
          {{ item.dateFromStr ? momentDate(item.dateFromStr) : '' }}
        </td>

        <!-- date to -->
        <td>
          {{ item.dateToStr ? momentDate(item.dateToStr) : '' }}
        </td>

        <!-- created -->
        <td>
          <div><small>{{ item.account === null ? '' : item.account.name }}</small></div>
          <div>{{ formatFETimestamp(item.createdAtStr) }}</div>
        </td>

        <!-- last updated -->
        <td>
          <div>
            <div><small>{{ item.lastUpdateBy === null ? '?' : item.lastUpdateBy }}</small></div>
            <div>{{ formatFETimestamp(item.lastUpdateStr) }}</div>
          </div>
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around" :style="{ visibility : canReadOnly ? 'hidden' : 'visible' }">
            <vehicle-request-edit :item="item" @updated="$refs.smartTable.getTableRows()" :key="`edit-${item.id}`"/>
          </div>
        </td>
      </template>

      <!-- smart table slot FOOTER-ITEM-1: print report -->
      <template v-slot:FOOTER-ITEM-1>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="main"
                    @click="vehicleRequestReport($refs.smartTable.getParams())">
              mdi-printer
            </v-icon>
          </template>
          {{ translations.exportList }}
        </v-tooltip>
      </template>
    </smart-table>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import translationsUtil from '@/utils/common/translationsUtil'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import { vehicleRequestReport } from '@/utils/api/reportsApi'
import VehicleRequestService from '@/service/vehicleRequest/VehicleRequestService'

import SmartTable from '@/components/SmartTable'
import VehicleRequestEdit from '@/views/VehicleRequest/VehicleRequestEdit'
import VehicleRequestNew from '@/views/VehicleRequest/VehicleRequestNew'
import { mapState } from 'vuex'

export default {
  name: 'VehicleRequest',

  components: {
    SmartTable,
    VehicleRequestNew,
    VehicleRequestEdit
  },

  data() {
    return {
      translations: translations,
      translationsUtil: translationsUtil
    }
  },

  mixins: [MomentMixin],

  methods: {
    vehicleRequestReport,

    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'code',
        label: translations.vehicles,
        sort: 'vehicle.code'
      })

      headers.push({
        type: 'TEXT',
        name: 'note',
        label: translations.notes + ' / ' + translations.equipments,
        sort: 'note'
      })

      headers.push({
        type: 'SELECT',
        name: 'types',
        label: translations.operationType,
        selectItems: this.$store.getters.vehicleOperations,
        sort: 'type'
      })

      headers.push({
        type: 'SELECT',
        name: 'priorities',
        label: translations.priority,
        selectItems: this.$store.getters.prioritiesSelect,
        sort: 'priority'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'datePlannedStr',
        label: translations.plannedDate,
        sort: 'datePlanned'
      })

      headers.push({
        type: 'SELECT',
        name: 'statuses',
        label: translations.status,
        selectItems: this.$store.getters.vehicleRequestStatusesSelect,
        sort: 'status'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateFromStr',
        label: translations.date + ' (' + translations.from + ')',
        sort: 'dateFrom'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateToStr',
        label: translations.date + ' (' + translations.to + ')',
        sort: 'dateTo'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'createdAtStr',
        label: translations.createdAt,
        sort: 'createdAt'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'lastUpdateStr',
        label: translations.lastUpdate,
        sort: 'lastUpdate'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return VehicleRequestService.search(params)
    }
  },

  computed: {
    ...mapState({
      canReadOnly: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Read-only')
    })
  }
}
</script>
