<template>
  <v-row class="ma-0" justify="center">
    <!-- new request dialog -->
    <v-dialog v-model="showDialog"
              @click:outside="closeHandler"
              @keydown="value => value.key === 'Escape' ? closeHandler() : ''"
              scrollable max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" @click="showDialog = true" color="main" class="white--text" small depressed :style="{ visibility: canReadOnly ? 'hidden' : 'visible' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <v-card class="pa-1">
        <!-- title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations.newRequest }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- section employee -->
          <div class="section-divider mt-1">
            <div class="section-divider-title">{{ translations.employee }}</div>

            <!-- employee -->
            <v-col class="pa-0 mt-1">
              <b class="ml-8">{{ translations.employee }} *</b>
              <v-menu max-height="200px" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field ref="employeeFilter" v-on="on" v-bind="attrs"
                                name="none"
                                placeholder="Cerca addetto"
                                v-model="employeePlaceHolder"
                                :loading="$apollo.queries.employees.loading"
                                autocomplete="off"
                                outlined hide-details dense clearable
                                @click:clear="employeeForFilterSelection(null)"
                                @input="getFilteredEmployees()"
                                prepend-icon="mdi-account">
                  </v-text-field>
                </template>
                <v-list>
                  <v-list-item v-for="emp in employeeList" :key="emp.id" @click="employeeForFilterSelection(emp)">
                    {{ emp.name }}
                  </v-list-item>

                  <v-list-item v-if="employeeList.length === 0 && !$apollo.queries.employees.loading">
                    {{ translations.noEmployeesFoundWThatName }}
                  </v-list-item>
                </v-list>

                <div v-if="hasNextEmployees && !$apollo.queries.employees.loading" v-intersect="onIntersect" style="background: white;" class="d-flex justify-center">
                  <v-progress-circular indeterminate color="main"></v-progress-circular>
                </div>
              </v-menu>
            </v-col>
          </div>


          <!-- section details -->
          <div class="section-divider mt-12">
            <div class="section-divider-title">{{ translations.details }}</div>

            <!-- note -->
            <v-col class="pa-0 mt-6">
              <b class="ml-8">{{ translations.additionalInfo }} *</b>
              <v-textarea v-model="note" prepend-icon="mdi-text-long" filled flat dense hide-details />
            </v-col>

            <!-- status -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.status }} *</b>
              <v-select v-model="status" :items="$store.getters.dpiStatusesSelect"
                        prepend-icon="mdi-progress-check"
                          outlined dense hide-details>
                  <template v-slot:selection="{ item }">
                    <v-chip small label class="status-chip" :color="item.background">
                      <div :style="[{ color: item.color }]">{{ item.text }}</div>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip small label class="status-chip" :color="item.background">
                      <div :style="[{ color: item.color }]">{{ item.text }}</div>
                    </v-chip>
                  </template>
                </v-select>
            </v-col>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="center">
            <v-btn @click="saveDPI" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ translations.confirm }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.overlappingDatesError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>

<script>
import debounce from 'lodash/debounce'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import DpiService from '@/service/dpi/DpiService'

import employeesQuery from '@/graphql/Employees.gql'

import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'DpiNew',

  components: {
    MessageDialog,
    WarningSvg
  },

  props: {
    canReadOnly: {
      type: Boolean,
      default: () => false
    }
  },

  apollo: {
    employees: {
      query: employeesQuery,
      variables() {
        return {
          page: this.page,
          pageSize: 20,
          sort: 'name',
          order: 'asc',
          name: this.employeeToSearch,
          dateStr: this.today
        }
      },
      fetchPolicy: 'no-cache',
      result({ data }) {
        const { content, hasNext } = data.employees

        this.employeeList.push(...content)
        this.hasNextEmployees = hasNext
      }
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      employeeToSearch: '',
      employeePlaceHolder: '',
      hasNextEmployees: false,
      employeeList: [],
      page: 1,

      employeeId: '',
      status: this.$store.getters.dpiStatusesSelect[0].value,
      note: ''
    }
  },

  computed: {
    disableBtn() {
      return !this.employeeId || !this.status || !this.note
    }
  },

  mixins: [MomentMixin],

  methods: {
    getFilteredEmployees: debounce(function() {
      this.employeeList = []
      this.page = 1
      this.employeeToSearch = this.employeePlaceHolder
    }, 650),

    employeeForFilterSelection(employee) {
      if (!employee) {
        this.$refs.employeeFilter.blur()

        this.employeeId = ''
        this.employeePlaceHolder = ''
        return
      }
      this.employeeId = employee.id
      this.employeePlaceHolder = employee.name
    },

    closeHandler() {
      this.employeeId = ''
      this.status = this.$store.getters.dpiStatusesSelect[0].value
      this.note = ''
      this.employeeToSearch = ''
      this.employeePlaceHolder = ''
    },

    async saveDPI() {
      this.loading = true
      await DpiService.save({
          dpiDTO: {
            employee: {
              id: this.employeeId
            },
            status: this.status,
            note: this.note
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page++
      }
    }
  }
}
</script>
