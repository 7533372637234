<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="ActivityRequest">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <activity-request-new @updated="$refs.smartTable.getTableRows()" :canReadOnly="canReadOnly"/>
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- employee name -->
        <td>
          <v-tooltip :disabled="!(item.employee.employmentEndDateStr < today)" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs" :style="item.employee.employmentEndDateStr < today ? 'color:red;font-style:italic' : ''">{{ item.employee.name }}</span>
            </template>

            {{ translations.employeeNotActiveRequestExplain }}: {{ item.employee.employmentEndDateStr }}
          </v-tooltip>
        </td>

        <!-- type -->
        <td>
          {{ item.employeeStatus.description }}
        </td>

        <!-- date from -->
        <td>
          {{ momentDate(item.dateFromStr) }}
        </td>

        <!-- date to -->
        <td>
          {{ momentDate(item.dateToStr) }}
        </td>

        <!-- day part -->
        <td>
          {{ $store.getters.activityRequestDayPartsMap[item.dayPart] }}
        </td>

        <!-- status -->
        <td>
          <v-tooltip top color="black" :disabled="!item.note || item.note.length === 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" small label class="status-chip mr-2"
                      :color="$store.getters.activityRequestStatuses[item.status].background">
                <div :style="[{ color: $store.getters.activityRequestStatuses[item.status].color }]">
                  {{ $store.getters.activityRequestStatuses[item.status].text }}
                  <span v-if="item.status === 'REJECTED' && item.note && item.note.length > 0">*</span>
                </div>
              </v-chip>
            </template>
            {{ item.note }}
          </v-tooltip>
        </td>

        <!-- created at -->
        <td>
          {{ formatFETimestamp(item.createdAtStr) }}
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around" :style="{ visibility : canReadOnly ? 'hidden' : 'visible' }">
            <activity-request-edit :item="item" @updated="$refs.smartTable.getTableRows()"/>
          </div>
        </td>
      </template>

      <!-- smart table slot FOOTER-ITEM-1: print report -->
      <template v-slot:FOOTER-ITEM-1>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" color="main"
                    @click="activityRequestReport($refs.smartTable.getParams())">
              mdi-printer
            </v-icon>
          </template>
          {{ translations.exportList }}
        </v-tooltip>
      </template>
    </smart-table>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import { activityRequestReport } from '@/utils/api/reportsApi'
import ActivityRequestService from '@/service/activityRequest/ActivityRequestService'

import SmartTable from '@/components/SmartTable'
import ActivityRequestEdit from '@/views/ActivityRequest/ActivityRequestEdit'
import ActivityRequestNew from '@/views/ActivityRequest/ActivityRequestNew'
import { mapState } from 'vuex'

export default {
  name: 'ActivityRequest',

  components: {
    SmartTable,
    ActivityRequestEdit,
    ActivityRequestNew
  },

  data() {
    return {
      translations: translations
    }
  },

  computed: {
    today() {
      return this.getSimpleDay().slice(0, 10)
    },

    ...mapState({
      canReadOnly: state => state.auth.userInfo.account?.capabilities.some(c => c.name === 'Read-only')
    })
  },

  mixins: [MomentMixin],

  methods: {
    activityRequestReport,

    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'employeeName',
        label: translations.employee,
        sort: 'employee.name'
      })

      headers.push({
        type: 'SELECT',
        name: 'employeeStatuses',
        label: translations.requestType,
        selectItems: this.$store.getters.employeeStatusesSelect,
        sort: 'employeeStatus.description'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateFromStr',
        label: translations.date + ' (' + translations.from + ')',
        sort: 'dateFrom'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateToStr',
        label: translations.date + ' (' + translations.to + ')',
        sort: 'dateTo'
      })

      headers.push({
        type: 'SELECT',
        name: 'dayParts',
        label: translations.dayPart,
        selectItems: this.$store.getters.activityRequestDayParts,
        sort: 'dayPart'
      })

      headers.push({
        type: 'SELECT',
        name: 'statuses',
        label: translations.status,
        selectItems: this.$store.getters.activityRequestStatusesSelect,
        sort: 'status'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'createdAtStr',
        label: translations.createdAt,
        sort: 'createdAt'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return ActivityRequestService.search(params)
    }
  }
}
</script>
